import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";  
import {PhlebotomyPill, SidebarModal, PhlebotomySelector, RenderIf} from '../../../components/' 
import styles from "./SelectedPhlebotomyOption.module.scss"; 

import { getFingerPrickCopy } from '../../../helpers/getFingerPrickCopy';
import { checkTestTypeIsSexualTest } from '../../../helpers/checkHasSexualTest';

export default function SelectedPhlebotomyOption(props) {    
 
  let tests = props.basketTestItems;  
  let phlebotomyNameOverride = '';
  let showChangeBtn = true;
  Object.keys(tests).map(function(key) { 
    if (parseInt(tests[key].testType) == 5) {
      phlebotomyNameOverride = tests[key].testCollectionMethod;
      showChangeBtn = false;
    } 

    if(tests[key].blockKitOptions == 1){
      showChangeBtn = false;
    }

    const isSexualTest = checkTestTypeIsSexualTest(tests[key].testType)
    if(isSexualTest){
      phlebotomyNameOverride = getFingerPrickCopy(tests[key].testType)
    }
  });

  const conditionals = {};
  if (showChangeBtn) {
    conditionals['data-bs-toggle'] = 'modal';
    conditionals['data-bs-target'] = '#phlebotomyOptions'; 
  }

  return ( 
    <div id={styles.selectedPhlebotomyOption} className="row mb-5">
      <div className="col-12">
        <div className="row mb-1">
          <div className="col-6">
            <h4>Collection method</h4>
          </div>
          {/* <div className="col-6 text-end">
            <a data-bs-toggle="modal" data-bs-target="#phlebotomyOptions" href="#">Change</a>
          </div> */}
        </div>
      </div>
      <div className="col-12" { ...conditionals}  href="#">
        <PhlebotomyPill disabled={false} phlebotomyId={props.phlebotomyOption} phlebotomyPrice={props.phlebotomyPrices[props.phlebotomyOption].phlebotomyPrice} phlebotomyName={phlebotomyNameOverride ? phlebotomyNameOverride : props.phlebotomyPrices[props.phlebotomyOption].phlebotomyName} active={true} showChangeBtn={showChangeBtn}/> 
      </div>
      <RenderIf isTrue={showChangeBtn}>
        <SidebarModal hideHeader={true} modalId={"phlebotomyOptions"}>
          <PhlebotomySelector onlySellPhlebotomyKits={props.onlySellPhlebotomyKits} modalId={"phlebotomyOptions"} partnerClinics={props.partnerClinics} phlebotomyError={props.phlebotomyError} basket={props.basket} guestCheckout={props.guestCheckout} tests={props.tests} updatePhlebotomyOption={props.updatePhlebotomyOption} phlebotomyOption={props.phlebotomyOption} phlebotomyPrices={props.phlebotomyPrices} disabledFingerPrick={props.disabledFingerPrick}/>
        </SidebarModal>
      </RenderIf>
    </div>
    
  )
}



 
import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import find from 'lodash/find'
import _, { forEach } from 'lodash'; 
import {BasketItems, BasketItem, SelectedPhlebotomyOption, CartSummary, VoucherCode, Loader, Button, DeliveryOptions, RenderIf, QuickPay} from '../../../components'
import calculateBasket from "../../../helpers/calculateBasket"
import styles from "./CheckoutStepOne.module.scss";  
import { useCheckElementVisibility } from '../../../hooks/useCheckElementVisibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

export default function CheckoutStepOne(props) {  
  const [subtotal, setSubtotal] = useState();
  const [forceGuestCheckout, setForceGuestCheckout] = useState(true);
  const [checkIfGuestSelected, setCheckIfGuestSelected] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [goToStepValue, setGoToStepValue] = useState(1);
  const [isCartOnViewport] = useCheckElementVisibility("cart-summary")
  const [quickPay, setShowQuickPay] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  useEffect(() => {
    //props.handleTracking(-1); 
    getValues(); 
  }, [props.reloadQuickPay, props.basketTestItems, props.subscriptionOption, props.basket, props.deliveryOption, props.guestCheckout, props.phlebotomyOption, props.cart, props.voucher, props.globalPlusTests]);

  const handleNext = () => {
    const {foundEndoTest, foundTrueCheckTest} = props
    if(foundEndoTest || foundTrueCheckTest){
      props.goToStep(6)
    } else {
      props.goToStep(goToStepValue, true, true)
    }
  }

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps);  
    setDeliveryPrice(checkoutTotal.deliveryPrice)
    setSubtotal(checkoutTotal.subtotal);
    setIsSubscription(checkoutTotal.subscription);
    let showQuickPay = true;

    let tests = props.basketTestItems;
    let basketArray = props.cart[0].basketArray;
    let items = [];
    let overrideGuest = false;

    if (Object.keys(props.cart).length > 0) { 

      var checkIfGuestSelected = false;
      var guestPurchase = props.cart[0].allowGuestPurchase;

      Object.keys(basketArray).map(function(key) {
        if (
          parseInt(basketArray[key][0]) == parseInt(38978) ||
          basketArray[key][0] == "38978" ||
          parseInt(basketArray[key][0]) == parseInt(40305) ||
          basketArray[key][0] == "40305" ||
          basketArray[key][0] == 42639 ||
          basketArray[key][0] == "42639" || tests[basketArray[key][0]].preventGuestPurchase == 1
        ) {
          guestPurchase = false;
          showQuickPay = false;
        }
      });

      if (props.phlebotomyOption != 1) {
        guestPurchase = false; 
      }

      if (props.phlebotomyOption != 1 && props.phlebotomyOption != 3 && props.phlebotomyOption != 5) {
        showQuickPay = false; 
      }

      var stateVoucher = localStorage.getItem("voucher");
      var voucher = props.voucher;
      if (!voucher) {
        voucher = JSON.parse(stateVoucher);
      }

      if (voucher && (voucher.orderCorporateId != "0" || voucher.orderCorporateId != 0)) {
        guestPurchase = false;
        showQuickPay = false;
      }

      if (props.subscriptionOption == 0) {
        if (guestPurchase && props.guestCheckout != "unset") {
          setCheckIfGuestSelected(true) 
        } else {
          setCheckIfGuestSelected(false); 
        }
      } else {
        setCheckIfGuestSelected(false);
      }
    }

    Object.keys(basketArray).map(function(key) {
      find(tests, (test) => {
        if (test.testId === basketArray[key][0]) {
          items.push(basketArray[key]);
        } else {
          return false;
        }

        if (basketArray[key][0] == 152286) {
          overrideGuest = true;
        }
      });
    }); 
    let grouped = _.mapValues(_.groupBy(items, "0"));
    
    let forceGuest = true;
    if (parseInt(Object.keys(grouped).length) == parseInt(Object.keys(props.cart[0].basketArray).length)) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }

    if (overrideGuest) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }
  
    setGoToStepValue(1);
    props.setSkipStepOne(false);
    /*
    if (props.subscriptionOption > 0 || forceGuestCheckout) {
      console.log("YES")
      setGoToStepValue(2);
      props.setSkipStepOne(true);
    } */ 
    
    if (Object.keys(props.globalPlusTests).length > 0) { 
      Object.keys(props.globalPlusTests).map(function(key) {
        let itemTests = props.globalPlusTests[key];
        Object.keys(itemTests).map(function(key) {
          if (itemTests[key]) {
            showQuickPay = false; 
          }
        });

      });

 
    }

     
    if (checkoutTotal.subscription) {
      showQuickPay = false;
    }

    if(props.foundTrueCheckTest){
      showQuickPay = false;
    }

    setShowQuickPay(showQuickPay)
    
  }
  
  const sendCloseIframe = (e) => {
    e.preventDefault();
    if(window.parent) {
      window.parent.postMessage('closeIframe', '*');
    }
  }

  let allowVoucher = true;
  if (Object.keys(props.user).length > 0 && props.user.checkoutCredit != 0) {
    allowVoucher = false;
  }

  let websiteUrl = "https://www.forthwithlife.co.uk";
  if (global.config.websiteId == 2) { 
    websiteUrl = "https://www.sportsbloodtests.co.uk";
  } 
  if (global.config.websiteId >= 3) {
    const partner = props.partner;
    if (!_.isEmpty(partner) && partner[0].requestCode) {
      websiteUrl = `https://biomarkertracking.com/${partner[0].requestCode}`;
    }
  }

  // let referrer = localStorage.getItem("referrer");
  // if (referrer && referrer != '""') {
  //   if (!referrer.includes('app') && !referrer.includes('shop') && !referrer.includes('localhost')) {
  //     websiteUrl = referrer.replace(/^"(.*)"$/, "$1");
  //   } else {
  //     websiteUrl = websiteUrl;
  //   }
  // }  

  const scrollToCartSummary = () => {
    document.querySelector(".cart-summary").scrollIntoView()
  }

  return ( 
    <div className="container pt-4">
      <div className="row"> 
        <div className="col-12 col-lg-8">
          <BasketItems 
            cart={props.cart}
            basketTestItems={props.basketTestItems}
            phlebotomyOption={props.phlebotomyOption}
            getVoucher={props.getVoucher}
            globalPlusTests={props.globalPlusTests}
            setPlusTests={props.setPlusTests}
            handleQuantityChange={props.handleQuantityChange}
            subscriptionInfo={props.subscriptionInfo}
            subscriptionOption={props.subscriptionOption}
            step={props.step} 
            partner={props.partner} 
            voucher={props.voucher} 
            handleSubscriptionChange={props.handleSubscriptionChange}
            quantityLoading={props.quantityLoading}
            globalHCTests={props.globalHCTests}
            setGlobalHCTests={props.setGlobalHCTests}
            updatePhlebotomyOption={props.updatePhlebotomyOption}
          />  
  
            <SelectedPhlebotomyOption 
              onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
              phlebotomyPrices={props.phlebotomyPrices}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              phlebotomyOption={props.phlebotomyOption}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              guestCheckout={props.guestCheckout}
              basketTestItems={props.basketTestItems}
              disabledFingerPrick={props.disabledFingerPrick}
            /> 
   
          {/* 
          <DeliveryOptions 
            deliveryOption={props.deliveryOption} 
            deliveryPrice={props.deliveryInfo?.deliveryFee || 0}
            basket={props.basket} 
            partner={props.partner}
            basketArray={props.cart[0].basketArray}
            state={{}} 
            updateDeliveryOption={props.updateDeliveryOption} 
            deliveryOptionDetails={props.deliveryOptionDetails}
            allowInternational={props?.stateVoucher?.allowInternational}
            deliveryInfo={props.deliveryInfo}
          />
          */}
        </div>
        <div id="cart-summary" className="col-12 col-lg-4 pb-5 cart-summary">
          <VoucherCode
            stateVoucher={props.stateVoucher}
            removeVoucher={props.removeVoucher}
            basket={props.basket} 
            applyVoucherCode={props.applyVoucherCode}
            voucherStatus={props.voucherStatus}
            voucherErrorCode={props.voucherErrorCode}
            allowVoucher={allowVoucher}
          />
          <CartSummary 
            onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
            deliveryInfo={props.deliveryInfo}
            customerDetails={props.customerDetails}
            phlebotomyPrices={props.phlebotomyPrices}
            voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
            subscriptionOption={props.subscriptionOption}
            step={props.step}
            globalPlusTests={props.globalPlusTests}
            deliveryOption={props.deliveryOption}
            guestCheckout={props.guestCheckout}
            subscriptionInfo={props.subscriptionInfo}
            cart={props.cart[0]}
            basketTestItems={props.basketTestItems}
            basketArray={props.cart[0].basketArray}
            phlebotomyOption={props.phlebotomyOption}
            voucher={props.voucher}
            user={props.user}
            partner={props.partner}
            quantityLoading={props.quantityLoading}
            nextDayDeliveryPrice={props.nextDayDeliveryPrice}
            partnerClinics={props.partnerClinics}
            basket={props.basket}
            tests={props.basketTestItems}
            updatePhlebotomyOption={props.updatePhlebotomyOption}
            phlebotomyError={props.phlebotomyError}
            updateDeliveryOption={props.updateDeliveryOption} 
            allowInternational={props?.stateVoucher?.allowInternational}
            globalHCTests={props.globalHCTests}
            disabledFingerPrick={props.disabledFingerPrick}
          />


          <div className="p-3 pt-0 pb-0">
            <Button onClick={handleNext} className="w-100 mb-3">Pay by Credit/Debit{!isSubscription && subtotal > 50  ? '/Klarna' : ''}</Button>
          </div>  
 
          { quickPay && (
            <div className="p-3 pt-0"> 
              <QuickPay  
                deliveryInfo={props.deliveryInfo}
                setGuestCheckout={props.setGuestCheckout} 
                setGuestCheckoutValue={props.setGuestCheckoutValue} 
                loadCreateAccount={props.loadCreateAccount}
                basketTestItems={props.basketTestItems}
                user={props.user}
                basket={props.cart[0].basketArray}
                subscriptionOption={props.subscriptionOption}
                phlebotomyOption={props.phlebotomyOption}
                loadClearBasket={props.loadClearBasket}
                loadAddToBasket={props.loadAddToBasket}
                stripeCustomerId={props.stripeCustomerId}
                setStripeCustomerId={props.setStripeCustomerId}
                paymentIntent={props.paymentIntent}
                setPaymentIntent={props.setPaymentIntent}
                setClientSecret={props.setClientSecret}
                clientSecret={props.setClientSecret}
                loadGetCart={props.loadGetCart}
                updateOrderWithDeliveryOption={props.updateOrderWithDeliveryOption}
                cart={props.cart}
                phlebotomyPrices={props.phlebotomyPrices}
                globalPlusTests={props.globalPlusTests}
                step={props.step}
                deliveryOption={props.deliveryOption}
                voucher={props.voucher}
                partner={props.partner}
                guestCheckout={true} 
                setCart={props.setCart}
                loadUpdateBasketReference={props.loadUpdateBasketReference}
                updatePaymentIntent={props.updatePaymentIntent}
                reloadQuickPay={props.reloadQuickPay}
                setReloadQuickPay={props.setReloadQuickPay}
                updateUserProfile={props.updateUserProfile} 
                setPaymentSuccess={props.setPaymentSuccess}
                removeAllFromCart={props.removeAllFromCart}
                goToStep={props.goToStep}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                trackQuickPay={props.trackQuickPay}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />  
            </div>
          )} 


          <div className="p-3 pt-0">
            { window !== window.parent ? (
              <Button variant="link" className={"mt-2 w-100 "+styles.backToShopButton} onClick={(e) => sendCloseIframe(e) }>Continue shopping</Button>
            ) : (
              <Button variant="link" href={websiteUrl} className={styles.backToShopButton}>Continue shopping</Button>
            )} 
          </div>

          
           
          
        </div>

        <div className={`${styles.scrollButton} ${isCartOnViewport ? "d-none" : "d-flex"} align-items-center d-md-none position-sticky sticky-bottom py-3 px-2`} onClick={scrollToCartSummary}>
          <p className="col mb-0 text-center">Skip to cart summary</p> 
          <FontAwesomeIcon icon={faChevronDown} className="fa-fw col-auto pe-4" />
        </div>
      </div> 
    </div>     
  )
  
}
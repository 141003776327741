import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import find from 'lodash/find'
import _ from 'lodash'; 
import {BasketItems, CartSummary, CheckoutTypeOption, CheckoutProgressBar, YourOrderWrapper, Button, RenderIf} from '../../../components'
import calculateBasket from "../../../helpers/calculateBasket"
import styles from "./CheckoutStepTwo.module.scss"; 

export default function CheckoutStepTwo(props) {  
  const {foundEndoTest, foundTrueCheckTest} = props
  const [subtotal, setSubtotal] = useState();
  const [basketTotal, setBasketTotal] = useState();
  const [forceGuestCheckout, setForceGuestCheckout] = useState(true);
  const [checkIfGuestSelected, setCheckIfGuestSelected] = useState(false);
  const [giftCardTotal, setGiftCardTotal] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [testPrice, setTestPrice] = useState();

  useEffect(() => { 
    getValues(); 
  },[props]);

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps); 
    setGiftCardTotal(checkoutTotal.giftCardTotal)
    setBasketTotal(checkoutTotal.testPrice);
    setSubtotal(checkoutTotal.subtotal);
    setTestPrice(checkoutTotal.testPrice); 
    let tests = props.basketTestItems;
    let basketArray = props.cart[0].basketArray;
    let items = [];
    let overrideGuest = false;

    if (Object.keys(props.cart).length > 0) { 

      var checkIfGuestSelected = false;
      var guestPurchase = props.cart[0].allowGuestPurchase;

      Object.keys(basketArray).map(function(key) {
        if (
          parseInt(basketArray[key][0]) == parseInt(38978) ||
          basketArray[key][0] == "38978" ||
          parseInt(basketArray[key][0]) == parseInt(40305) ||
          basketArray[key][0] == "40305" ||
          basketArray[key][0] == 42639 ||
          basketArray[key][0] == "42639"
        ) {
          guestPurchase = false;
        }
      });

      if (props.phlebotomyOption != 1) {
        guestPurchase = false;
      }

      var stateVoucher = localStorage.getItem("voucher");
      var voucher = props.voucher;
      if (!voucher) {
        voucher = JSON.parse(stateVoucher);
      }

      if (voucher && (voucher.orderCorporateId != "0" || voucher.orderCorporateId != 0)) {
        guestPurchase = false;
      }

      if (props.subscriptionOption == 0) {
        if (guestPurchase && props.guestCheckout != "unset") {
          setCheckIfGuestSelected(true) 
        } else {
          setCheckIfGuestSelected(false); 
        }
      } else {
        setCheckIfGuestSelected(false);
      }
    }

    Object.keys(basketArray).map(function(key) {
      find(tests, (test) => {
        if (test.testId === basketArray[key][0]) {
          items.push(basketArray[key]);
        } else {
          return false;
        }

        if (basketArray[key][0] == 152286) {
          overrideGuest = true;
        }
      });
    }); 
    let grouped = _.mapValues(_.groupBy(items, "0"));
//    setForceGuestCheckout(true);
    let forceGuest = true;
    if (parseInt(Object.keys(grouped).length) == parseInt(Object.keys(props.cart[0].basketArray).length)) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }

    if (overrideGuest) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }


    if (props.guestCheckout == 'unset') {
      props.setGuestCheckoutValue(false);
    } 
    
  }
  
  return ( 
    <>
      <div className="row d-block d-md-none pt-0 pt-md-5 g-0 w-100">
        <div className="col-12">
          <YourOrderWrapper subtotal={subtotal} totalAmount={parseFloat(testPrice)} partner={props.partner} checkoutFromPartner={props.checkoutFromPartner} step={props.step}>
            <BasketItems
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              phlebotomyOption={props.phlebotomyOption}
              getVoucher={props.getVoucher}
              globalPlusTests={props.globalPlusTests}
              setPlusTests={props.setPlusTests}
              handleQuantityChange={props.handleQuantityChange}
              subscriptionInfo={props.subscriptionInfo}
              subscriptionOption={props.subscriptionOption}
              step={props.step} 
              partner={props.partner} 
              voucher={props.voucher} 
              handleSubscriptionChange={props.handleSubscriptionChange}
              globalHCTests={props.globalHCTests}
              setGlobalHCTests={props.setGlobalHCTests}
            />
            <CartSummary 
              onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
              deliveryInfo={props.deliveryInfo}
              customerDetails={props.customerDetails}
              phlebotomyPrices={props.phlebotomyPrices}
              voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
              subscriptionOption={props.subscriptionOption}
              step={props.step}
              globalPlusTests={props.globalPlusTests}
              deliveryOption={props.deliveryOption}
              guestCheckout={props.guestCheckout}
              subscriptionInfo={props.subscriptionInfo}
              cart={props.cart[0]}
              basketTestItems={props.basketTestItems}
              basketArray={props.cart[0].basketArray}
              phlebotomyOption={props.phlebotomyOption}
              voucher={props.voucher}
              user={props.user}
              partner={props.partner}
              nextDayDeliveryPrice={props.nextDayDeliveryPrice}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              updateDeliveryOption={props.updateDeliveryOption} 
              allowInternational={props?.stateVoucher?.allowInternational}
              globalHCTests={props.globalHCTests}
            /> 
          </YourOrderWrapper>
             
        </div>
      </div>
      <div className="container py-5 z">
        <div className="row">  
          <div className="col-12 col-md-6">
            <CheckoutProgressBar showQuestionAdditionalStep={foundEndoTest || foundTrueCheckTest} goToStep={props.goToStep} step={props.step} checkoutFromPartner={props.checkoutFromPartner} guestCheckout={props.guestCheckout}/>
            <CheckoutTypeOption 
              cart={props.cart}
              partner={props.partner}
              guestError={props.guestError}
              guestCheckout={props.guestCheckout}
              setGuestCheckoutValue={props.setGuestCheckoutValue}
              forceGuestCheckout={forceGuestCheckout}
              phlebotomyOption={props.phlebotomyOption}
              subscriptionOption={props.subscriptionOption}
              basketTestItems={props.basketTestItems}
              foundTrueCheckTest={foundTrueCheckTest}
            />  

            <RenderIf isTrue={giftCardTotal != 0.00 && props.guestCheckout}>
              <p className="d-block d-md-none text-danger mb-5">
                The code you have applied will have credit remaining after this purchase. To be able to apply this credit to your account, an account is required during purchase. If you opt to proceed with purchasing for someone else, this credit may be lost.
              </p>
            </RenderIf>

            <div className="col-12">
              <Button className="w-100 mb-3" disabled={isNextButtonDisabled} onClick={() => {setIsNextButtonDisabled(true); props.generateCheckoutData(checkIfGuestSelected, false)}} >
                Next - Your details
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none d-md-block"> 
            <YourOrderWrapper>
              <BasketItems
                cart={props.cart}
                basketTestItems={props.basketTestItems}
                phlebotomyOption={props.phlebotomyOption}
                getVoucher={props.getVoucher}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                step={props.step} 
                partner={props.partner} 
                voucher={props.voucher} 
                handleSubscriptionChange={props.handleSubscriptionChange}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
              <CartSummary 
                onlySellPhlebotomyKits={props.onlySellPhlebotomyKits}
                deliveryInfo={props.deliveryInfo}
                customerDetails={props.customerDetails}
                phlebotomyPrices={props.phlebotomyPrices}
                voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
                subscriptionOption={props.subscriptionOption}
                step={props.step}
                globalPlusTests={props.globalPlusTests}
                deliveryOption={props.deliveryOption}
                guestCheckout={props.guestCheckout}
                subscriptionInfo={props.subscriptionInfo}
                cart={props.cart[0]}
                basketTestItems={props.basketTestItems}
                basketArray={props.cart[0].basketArray}
                phlebotomyOption={props.phlebotomyOption}
                voucher={props.voucher}
                user={props.user}
                partner={props.partner}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                partnerClinics={props.partnerClinics}
                basket={props.basket}
                tests={props.basketTestItems}
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                phlebotomyError={props.phlebotomyError}
                updateDeliveryOption={props.updateDeliveryOption} 
                allowInternational={props?.stateVoucher?.allowInternational}
                globalHCTests={props.globalHCTests}
              /> 
            </YourOrderWrapper> 
          </div>
        </div>
      </div> 
    </>   
  )
  
}